import React from "react";
import Layout from "../components/layout";
import Helmet from "react-helmet";

export default () => (
  <Layout>
    <Helmet>
      <meta charSet="utf-8" />
      <title>MUDr. Rastislav Šušol - Urologická ambulance</title>
      <meta
        name="google-site-verification"
        content="0g_Bkm6xJl9ZFoJuDG3OaYQdOMj-7qHkG5uA_KUdtPI"
      />
    </Helmet>

    <Helmet
      bodyAttributes={{
        class: "front"
      }}
    />

    <div className="hero row">
      <div className="inner">
        <div className="seven columns">
          <div id="highlighted">
            <div className="inner">
              <div className="region region-highlighted">
                <div className="content">
                  <p>
                    Mým cílem je poskytnout pacientovi co možná nejkomplexnejší
                    službu. Od první návštěvy přes vyšetření a léčbu až po
                    pooperační sledování.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="five columns portrait">&nbsp;</div>
      </div>
    </div>

    <div className="addresses row greenblock">
      <div className="three columns">
        <div className="inner" />
      </div>
      <div className="three columns">
        <div className="inner">
          <h4>Blansko</h4>
          <p>
            <strong>Adresa</strong>
            <br />
            K. H. Máchy 17
          </p>
          <p>
            <strong>Kontakt</strong>
            <br />
            Tel.: 733 740 449
          </p>
          <p>
            <strong>Ordinační hodiny</strong>
            <br />
            Úterý, Středa, Pátek 7.00 - 13.00 &nbsp;hod.&nbsp;
          </p>
        </div>
      </div>
      <div className="three columns">
        <div className="inner">
          <h4>Letovice</h4>
          <p>
            <strong>Adresa</strong>
            <br />
            Pod Klášterem 17
          </p>
          <p>
            <strong>Kontakt</strong>
            <br />
            Tel.: 516 426 114
          </p>
          <p>
            <strong>Ordinační</strong>
            <strong>&nbsp;hodiny</strong>
            <br />
            Čtvrtek 7.00 - 11.00 hod.
          </p>
        </div>
      </div>
      <div className="three columns">
        <div className="inner">
          <h4>Svitavy</h4>
          <p>
            <strong>Adresa</strong>
            <br />
            Hybešova 2
          </p>
          <p>
            <strong>Kontakt</strong>
            <br />
            Tel.: 739 995 490
          </p>
          <p>
            <strong>Ordinační</strong>
            <strong>&nbsp;hodiny</strong>
            <br />
            Pondělí 6.00 - 8.00 hod., 13.00 - 16.00 hod.
            <br />
            Čtvrtek 12.00 - 15.00
          </p>
        </div>
      </div>
    </div>
  </Layout>
);
